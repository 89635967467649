import React from 'react'
import * as Bs from "react-bootstrap"
import TestimonialBlock from './TestimonialBlock'
import styled from "styled-components"
import { device } from "../../utils";
import {Section} from "../../components/Core";
import imgL1LogoWhite from "../../assets/image/png/ko.png";
import { Link } from "gatsby";


const SectionStyled = styled(Section)`
  
  background: #274069;
  background-size: cover;
  background-position: bottom left;
  padding-top: 2px;
  padding-bottom: 200px;

  @media ${device.lg} {
    background-position: center;
  }
`;

function Top(props) {


  return (
    <SectionStyled>
      <Bs.Container id='top'>
        <Bs.Row style={{display: 'block', textAlign: 'center', marginTop: 270, marginBottom: 0}}>
 
        
        <div className="brand-logo">
            <Link to={'/'}><img src={imgL1LogoWhite} alt="" id='logoMobile' style={{height: 50, marginLeft: -15}}/></Link>
            </div>
        {/* <h1 style={{color: 'white', fontSize: 65, fontWeight: 800, marginTop: 0, paddingLeft: 10, paddingRight: 10, letterSpacing: '-2.5px'}} id='line1'>{props.titleOne}</h1>
        <h1 style={{color: 'white', fontSize: 65, fontWeight: 800, marginTop: -10, paddingLeft: 10, paddingRight: 10, letterSpacing: '-2.5px'}} id='line1'>{props.titleTwo}</h1>
        <h1 style={{color: 'white', fontSize: 55, fontWeight: 800, marginTop: 0, paddingLeft: 10, paddingRight: 10, letterSpacing: '-2.5px'}} id='lineMobile'>{props.titleMobile}</h1>
        <h2 style={{color: 'white', fontWeight: 300, marginBottom: 25, paddingLeft: 10, paddingRight: 10, fontStyle: 'italic'}}>{props.subTitle}</h2> */}
        </Bs.Row>
        {/* <Checkout/>
        <Table name={props.tableName} target={props.target}/> */}

      </Bs.Container>
      </SectionStyled>
  )
}

export default Top
